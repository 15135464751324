import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";
import { Typography, Image, Button, API } from "shared";
import DoubleQuotation from "../../../../public/assets/doubleQuote.svg";
import { useRouter } from "next/router";

const useStyles = createUseStyles({
  testimonialWrapper: {
    display: "flex",
    justifyContent: "center",
    padding: "50px 30px 0px 30px",
    "@media(max-width: 768px)": {
      padding: "24px 28px 0px 28px",
    },
  },
  testimonial: {
    width: 954,
    "@media(min-width: 376px) and (max-width: 768px)": {
      width: 320,
    },
    "@media(max-width: 375px)": {
      width: "unset",
    },
  },
  testimonialTitle: {
    textAlign: "center",
  },
  testimonialContentWrapper: {
    display: "flex",
    flexDirection: "column",
    padding: "50px 0px",
    "@media(max-width: 768px)": { padding: "22px 0px 0px 0px" },
  },
  testimonialContentImageWrapper: {
    "@media(max-width: 768px)": {
      height: "unset",
    },
    height: 289,
  },
  testimonialContentImageWrapperMargin: {
    "@media(min-width: 769px)": {
      marginTop: 50,
    },
    marginTop: 26,
  },
  testimonialContent: {
    "@media(min-width: 769px)": {
      minHeight: 217,
      marginTop: 32,
      width: 580,
      bottom: 290,
      top: "unset",
      padding: "56px 47px 56px 100px",
    },
    borderRadius: 10,
    padding: "20px 11px 20px 46px",
    background: "rgba(242, 242, 242, 0.9)",
    position: "relative",
    top: -22,
    minHeight: 116,
    zIndex: 1,
  },
  testimonialContentLeft: {
    "@media(min-width: 769px)": {
      float: "left",
    },
  },
  testimonialContentRight: {
    "@media(min-width: 769px)": {
      float: "right",
    },
  },
  testimonialContentImage: {
    position: "absolute",
    left: 47,
    top: 56,
    "@media(max-width: 768px)": {
      left: 11,
      top: 20,
    },
  },
  testimonialImage: {
    "@media(max-width: 768px)": {
      width: 225,
      height: 126,
      left: 47,
    },
    "@media(max-width: 375px)": {
      left: 40,
    },
    "@media(max-width: 360px)": {
      left: 30,
    },
    "@media(max-width: 335px)": {
      left: 20,
    },
    width: 515,
    height: 290,
    position: "relative",
  },
  testimonialImageLeft: {},
  testimonialImageRight: {
    float: "right",
    "@media(max-width: 768px)": {
      float: "none",
    },
  },
  actualImage: {
    height: 289,
    width: 515,
    backgroundSize: "contain !important",
    "@media(max-width: 768px)": {
      width: 225,
      height: 126,
    },
  },
  testimonialButton: {
    "@media(max-width: 768px)": { width: 240 },
    "& >span": {
      fontSize: 12,
      lineHeight: "18px",
      "@media(min-width: 769px)": {
        fontSize: 18,
        lineHeight: "27px",
      },
    },
  },
  title2: {
    "@media(min-width: 769px)": {
      fontSize: 36,
      lineHeight: "52px",
    },
    fontSize: 22,
    lineHeight: "33px",
  },
  doubleQuote: {
    height: 22,
    width: 25,
    "@media(min-width: 769px)": {
      width: 38,
      height: 34,
    },
  },
  centerWrapper: {
    display: "flex",
    justifyContent: "center",
  },
  textClass1: {
    fontSize: 12,
    lineHeight: "18px",
    overflow: "hidden",
    overflowWrap: "normal",
    textOverflow: "ellipsis",
    display: "-webkit-box",
    "-webkit-line-clamp": 4,
    "-webkit-box-orient": "vertical",
    "@media(min-width: 769px)": {
      fontSize: 18,
      lineHeight: "26px",
    },
  },
  buttonClass1: {
    display: "flex",
    justifyContent: "center",
  },
});

const Testimonial = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const router = useRouter();
  const readMoreFeedbackHandler = () => {
    router.push("/customer-voice").then(() => window.scrollTo(0, 0));
  };

  const [reviews, setReviews] = useState([]);
  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    const response = await API.get("/reviews");
    if (response && response.data) {
      if (response.data.length <= 2) {
        setReviews(response.data);
      } else {
        response.data.sort((r1, r2) => (r1.id > r2.id ? -1 : 1));
        setReviews(response.data.slice(0, 2));
      }
    }
  };

  return (
    <div className={classes.testimonialWrapper}>
      {reviews.length > 0 ? (
        <div className={classes.testimonial}>
          <Typography
            className={`${classes.testimonialTitle} ${classes.title2}`}
          >
            {t("Customer Feedback")}
          </Typography>
          <div className={classes.testimonialContentWrapper}>
            <div className={classes.testimonialContentImageWrapper}>
              <div
                className={`${classes.testimonialImage} ${classes.testimonialImageRight}`}
              >
                <Image
                  img={reviews[0].image.replace("original", "thumbnail")}
                  className={classes.actualImage}
                  background="contain"
                />
              </div>
              <div
                className={`${classes.testimonialContent} ${classes.testimonialContentLeft}`}
              >
                <div className={classes.testimonialContentImage}>
                  <img className={classes.doubleQuote} src={DoubleQuotation} />
                </div>
                <Typography className={classes.textClass1}>
                  {reviews[0].review}
                </Typography>
              </div>
            </div>
            {reviews.length === 2 ? (
              <div
                className={`${classes.testimonialContentImageWrapper} ${classes.testimonialContentImageWrapperMargin}`}
              >
                <div
                  className={`${classes.testimonialImage} ${classes.testimonialImageLeft}`}
                >
                  <Image
                    img={reviews[1].image.replace("original", "thumbnail")}
                    className={classes.actualImage}
                    background="contain"
                  />
                </div>
                <div
                  className={`${classes.testimonialContent} ${classes.testimonialContentRight}`}
                >
                  <div className={classes.testimonialContentImage}>
                    <img
                      className={classes.doubleQuote}
                      src={DoubleQuotation}
                    />
                  </div>
                  <Typography className={classes.textClass1}>
                    {reviews[1].review}
                  </Typography>
                </div>
              </div>
            ) : (
              <></>
            )}
          </div>
          <div className={classes.buttonClass1}>
            <Button
              type="primary"
              size="large"
              className={classes.testimonialButton}
              onClick={readMoreFeedbackHandler}
            >
              {t("Read more feedback")}
            </Button>
          </div>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

export { Testimonial };
