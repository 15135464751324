import React from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";
import { Typography } from "shared";
import Delivery from "../../../../public/assets/delivery.svg";
import ReturnGuarentee from "../../../../public/assets/returnGuarentee.svg";
import Confirmation from "../../../../public/assets/confirmation.svg";

const useStyles = createUseStyles({
  policiesWrapper: {
    display: "flex",
    marginTop: 50,
    paddingBottom: 50,
    justifyContent: "space-evenly",
    "@media(max-width: 768px)": {
      flexDirection: "column",
      marginTop: 24,
      paddingBottom: 24,
      justifyContent: "center",
    },
  },
  groupCenter: {
    display: "flex",
    flexDirection: "column",
  },
  imageHeight: {
    height: 92,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "@media(max-width: 768px)": { height: "fit-content" },
  },
  icon1: {
    width: 88,
    height: 38,
    "@media(min-width: 769px)": {
      width: 128,
      height: 55,
    },
  },
  icon2: {
    width: 88,
    height: 81,
    "@media(min-width: 769px)": {
      width: 100,
      height: 91,
    },
  },
  icon3: {
    width: 88,
    height: 81,
    "@media(min-width: 769px)": {
      width: 108,
      height: 68,
    },
  },
  policiesText: {
    marginTop: 17,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    "@media(max-width: 768px)": {
      marginTop: 22,
    },
  },
  policiesDetails: {
    marginTop: 17.5,
    width: 246,
    "@media(max-width: 768px)": {
      marginTop: 12,
      width: 181,
    },
  },
  marginPolicy: {
    "@media(max-width: 768px)": {
      marginBottom: 48,
    },
  },
  title1: {
    color: "#82999B",
    fontSize: 24,
    lineHeight: "36px",
    "@media(max-width: 768px)": {
      fontSize: 16,
      lineHeight: "24px",
    },
  },
  body1: {
    color: "#82999B",
    fontSize: 18,
    lineHeight: "27px",
    textAlign: "center",
    textJustify: "inter-word",
    "@media(max-width: 768px)": {
      fontSize: 12,
      lineHeight: "18px",
    },
  },
});

const Policy = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <div className={classes.policiesWrapper}>
      <div className={`${classes.groupCenter} ${classes.marginPolicy}`}>
        <div className={classes.imageHeight}>
          <img className={classes.icon1} src={Delivery} />
        </div>
        <div className={classes.policiesText}>
          <Typography className={classes.title1}>
            {t("Free Shipping")}
          </Typography>
          <div className={classes.policiesDetails}>
            <Typography className={classes.body1}>
              {t("Free and speedy shipping with careful packing")}
            </Typography>
          </div>
        </div>
      </div>
      <div className={`${classes.groupCenter} ${classes.marginPolicy}`}>
        <div className={classes.imageHeight}>
          <img className={classes.icon2} src={ReturnGuarentee} />
        </div>
        <div className={classes.policiesText}>
          <Typography className={classes.title1}>
            {t("Returns Guarantee")}
          </Typography>
          <div className={classes.policiesDetails}>
            <Typography className={classes.body1}>
              {t("Returns can be made within 7 days of receiving the package")}
            </Typography>
          </div>
        </div>
      </div>
      <div className={classes.groupCenter}>
        <div className={classes.imageHeight}>
          <img className={classes.icon3} src={Confirmation} />
        </div>
        <div className={classes.policiesText}>
          <Typography className={classes.title1}>
            {t("Genuine Product")}
          </Typography>
          <div className={classes.policiesDetails}>
            <Typography className={classes.body1}>
              {t(
                "You can actually check the carpet before purchasing. Please contact us for details"
              )}
            </Typography>
          </div>
        </div>
      </div>
    </div>
  );
};

export { Policy };
