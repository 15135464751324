import React from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";
import { Typography, Image, Button } from "shared";
import { useRouter } from "next/router";

const useStyles = createUseStyles({
  carpetFinder: {
    marginTop: 50,
    marginBottom: 50,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-evenly",
    padding: "0px 30px",
    "@media(max-width: 768px)": {
      padding: "0px 28px",
      marginBottom: 24,
      flexDirection: "column",
    },
  },
  finderImage: {
    width: 528,
    height: 327,
    backgroundSize: "contain !important",
    "@media(max-width: 768px)": {
      width: 319,
      height: 192,
    },
  },
  inquirySubtitle: {
    color: "#82999B",
    fontSize: 18,
    lineHeight: "27px",
    "@media(max-width: 768px)": {
      fontSize: 12,
      lineHeight: "18px",
    },
  },
  finderWrapper: {
    marginLeft: 33,
    "@media(max-width: 768px)": {
      marginLeft: 0,
    },
  },
  inquiryText: {
    marginLeft: 0,
    fontSize: 36,
    lineHeight: "54px",
    "@media(max-width: 768px)": {
      marginTop: 22,
      marginBottom: 12,
      marginLeft: 0,
      fontSize: 16,
      lineHeight: "24px",
    },
  },
  buttonWrapper: {
    "@media(max-width: 768px)": {
      display: "flex",
      justifyContent: "center",
    },
  },
  finderButton: {
    background: "#009688",
    width: 314,
    marginTop: 15,
    display: "flex",
    justifyContent: "center",
    color: "#fff",
    "&:hover": {
      background: "#009688",
      color: "#fff",
    },
    "&:focus": {
      background: "#009688",
      color: "#fff",
    },
    "@media(max-width: 768px)": {
      marginTop: 22,
      width: 240,
    },
    "& >span": {
      fontSize: 18,
      lineHeight: "27px",
      "@media(max-width: 768px)": {
        fontSize: 12,
        lineHeight: "18px",
      },
    },
  },
});

const FindCarpet = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const router = useRouter();

  const requestFormHandler = () => {
    router.push("/request").then(() => window.scrollTo(0, 0));
  };

  return (
    <div>
      <div className={classes.carpetFinder}>
        <Image
          img={"/assets/request-image.png"}
          className={classes.finderImage}
          background="contain"
        />
        <div className={classes.finderWrapper}>
          <Typography type="title2" className={classes.inquiryText}>
            {t("Cannot find what you need ?")}
          </Typography>
          <Typography type="body1" className={classes.inquirySubtitle}>
            {t(
              "If you cannot find the carpet you are looking for, Please let us know your requirements."
            )}
          </Typography>
          <Typography type="body1" className={classes.inquirySubtitle}>
            {t(
              "We will use our unique team network to find and introduce the carpet that suits your needs."
            )}
          </Typography>
          <div className={classes.buttonWrapper}>
            <Button
              onClick={requestFormHandler}
              type="primary"
              size="large"
              className={classes.finderButton}
            >
              {t("Request Here")}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export { FindCarpet };
