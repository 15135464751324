import React from "react";
import { useTranslation } from "react-i18next";
import { Typography } from "shared";
import { createUseStyles } from "react-jss";
import Badge from "../../../../public/assets/badge.svg";
import Price from "../../../../public/assets/price.svg";

const useStyles = createUseStyles({
  golestanDetailsWrapper: {
    backgroundColor: "#6F8CA5",
    height: "fit-content",
    marginTop: 50,
    marginLeft: 30,
    marginRight: 30,
    display: "flex",
    flexDirection: "row",
    padding: {
      bottom: 30,
      right: 20,
    },
    justifyContent: "space-evenly",
    "@media(max-width: 768px)": {
      flexDirection: "column",
      padding: "24px 43px",
      marginTop: 24,
      marginLeft: 0,
      marginRight: 0,
    },
  },
  golestanReason: {
    marginTop: 210,
    marginLeft: 35,
    "@media(max-width: 768px)": {
      margin: 0,
      display: "flex",
      justifyContent: "center",
    },
  },
  whiteText: {
    color: "#F2F2F2",
  },
  reasons: {
    "@media(min-width: 769px)": {
      marginLeft: 162,
      width: 395,
      marginTop: 50,
    },
    marginTop: 22,
  },
  headingAlignment: {
    display: "flex",
    alignItems: "center",
    marginBottom: 30,
    "@media(max-width: 768px)": {
      marginBottom: 12,
    },
  },
  badge: {
    "@media(min-width: 769px)": {
      marginRight: 20,
      width: 50,
      height: 66,
    },
    position: "relative",
    marginRight: 12,
    width: 18,
    height: 24,
  },
  bottomHeading: {
    marginTop: 42,
    "@media(max-width: 768px)": { marginTop: 48 },
  },
  priceTag: {
    position: "relative",
    top: -3,
    marginRight: 20,
    width: 50,
    height: 45,
    "@media(max-width: 768px)": {
      top: 1,
      width: 27,
      height: 24,
      marginRight: 12,
    },
  },
  title2: {
    "@media(min-width: 769px)": {
      fontSize: 36,
      lineHeight: "52px",
    },
    fontSize: 22,
    lineHeight: "33px",
  },
  body1: {
    "@media(min-width: 769px)": {
      fontSize: 18,
      lineHeight: "26px",
    },
    fontSize: 12,
    lineHeight: "18px",
  },
});

const ReasonToChoose = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <div className={classes.golestanDetailsWrapper}>
      <div className={classes.golestanReason}>
        <Typography className={`${classes.title2} ${classes.whiteText}`}>
          {t("Why Golestan?")}
        </Typography>
      </div>
      <div className={classes.reasons}>
        <div className={classes.headingAlignment}>
          <img src={Badge} className={classes.badge} />
          <Typography className={`${classes.title2} ${classes.whiteText}`}>
            {t("Quality Assurance")}
          </Typography>
        </div>
        <Typography className={`${classes.body1} ${classes.whiteText}`}>
          {t(
            "We check each carpet one by one and issue a certificate so that customers can purchase with confidence."
          )}
        </Typography>
        <div className={`${classes.headingAlignment} ${classes.bottomHeading}`}>
          <img src={Price} className={classes.priceTag} />
          <Typography className={`${classes.title2} ${classes.whiteText}`}>
            {t("Reasonable Price")}
          </Typography>
        </div>
        <Typography className={`${classes.body1} ${classes.whiteText}`}>
          {t(
            "By selling online, we are able to reduce the cost of sales and provide high quality Persian carpet at affordable prices."
          )}
        </Typography>
      </div>
    </div>
  );
};

export { ReasonToChoose };
